import * as React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import "../css/typography.css"

const MainDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 74vh;

  h2 {
    font-family: 'S Bold';
  }
`


const NotFoundPage = () => {
  return (
    <Layout>
      <MainDiv>
        <h2>Esta página no existe</h2>
      </MainDiv>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
